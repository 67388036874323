import Layout from "../../components/Layout.js"
import {useEffect, useState} from "react"
import {useAuth} from "../../hooks/useAuth.js"
import useBackendApi from "../../services/backend.js"
import {Navigate} from 'react-router-dom'
import AddDrawer from "./AddDrawer.js"
import {toast} from "react-toastify"

const ManageAccess = () => {
  const {user} = useAuth()
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [visibleAdd, setVisibleAdd] = useState(false)

  const Api = useBackendApi()

  const onAdd = () => {
    setVisibleAdd(true)
  }

  const addUser = (data) => {
    if (!loading) {
      setLoading(true)
      Api.addNewUser(data)
        .then(res => {
          if (res.ok) {
            subscribeAccounts()
          } else {
            toast.error(res.data?.message)
          }
        })
        .catch(e => {
        })
        .finally(() => {
          setLoading(false)
          setVisibleAdd(false)
        })
    }
  }

  const toggleDisabled = (it) => {
    if (!loading) {
      setLoading(true)
      Api.setUserDisabled({uid: it.uid, disabled: !it.disabled})
        .then(res => {
          if (res.ok) {
            subscribeAccounts()
          }
        })
        .catch(e => {

        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const onDelete = (uid) => {
    if (!loading) {
      setLoading(true)
      Api.deleteUser({uid})
        .then(res => {
          if (res.ok) {
            subscribeAccounts()
          }
        })
        .catch(e => {

        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const subscribeAccounts = async () => {
    if (!loading) {
      setLoading(true)
      Api.getAllUsers()
        .then(res => {
          if (res.ok) {
            const {users} = res.data
            console.log(users)
            setRows(users)
          }
        })
        .catch(e => {
          console.log(`Error: ${e.message}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    subscribeAccounts()
  }, [])

  if (!user.isAdmin) {
    return <Navigate to={'/'}/>
  }

  return <Layout>
    <div className={'mb-3'}>
      <button className={'bg-primary text-white py-2 px-4 rounded-full font-bold cursor-pointer'} onClick={onAdd}>+ Add New</button>
    </div>
    <div className={'overflow-x-auto flex flex-col min-w-min'}>
      <div className={'grid grid-cols-8 w-full gap-4 font-bold w-full border-b-2 border-black'}>
        <div className={'col-span-3 sm:col-span-2'}>Username</div>
        <div className={'hidden sm:block col-span-2'}>Email</div>
        <div className={'hidden sm:block col-span-2'}>Active</div>
        <div className={'col-span-5 sm:col-span-2'}>Actions</div>
      </div>
      <div className={'overflow-y-auto h-table overflow-x-hidden'}>
        {rows.map((it, id) => {
          return <div className={'grid grid-cols-8 w-full gap-1 sm:gap-4 mt-2'} key={'user-' + id}>
            <div className={'col-span-3 sm:col-span-2 truncate'}>{it.name || it.uid}</div>
            <div className={'hidden sm:block col-span-2'}>{it.email}</div>
            <div className={'hidden sm:block col-span-2'}>{it.disabled ? 'No' : 'Yes'}</div>
            <div className={'col-span-5 sm:col-span-2 flex justify-evenly'}>
              <button className={`${it.disabled ? 'bg-info' : 'bg-primary'} text-white px-1 sm:px-8 cursor-pointer rounded-full font-bold `} onClick={() => toggleDisabled(it)}>{it.disabled ? 'Enable' : 'Disable'}</button>
              <button className={'bg-white text-black px-8 cursor-pointer rounded-full font-bold'} onClick={() => onDelete(it.uid)}>Delete</button>
            </div>
          </div>
        })}
      </div>
    </div>
    <div className={'flex flex-row justify-end mt-2 border-t border-black'}>
      <div className={'text-gray-500 text-sm italic'}>{rows.length} users</div>
    </div>
    <AddDrawer isOpen={visibleAdd} setIsOpen={setVisibleAdd} onOk={addUser}/>
  </Layout>
}

export default ManageAccess
