// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app"
import {getAnalytics} from "firebase/analytics"
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyDmS6iE-AUOzrqKRbmLY90jj4DSk92O1v8",
//   authDomain: "reddit-upvote-utility.firebaseapp.com",
//   projectId: "reddit-upvote-utility",
//   storageBucket: "reddit-upvote-utility.appspot.com",
//   messagingSenderId: "459459738596",
//   appId: "1:459459738596:web:35e2f12680d510b46065f1",
//   measurementId: "G-9W4D503TWK"
// }

const firebaseConfig = {
  apiKey: "AIzaSyC3QOfqPT799rvk6Ru6kCFneXBUlPv6oak",
  authDomain: "reddit-utility.firebaseapp.com",
  projectId: "reddit-utility",
  storageBucket: "reddit-utility.appspot.com",
  messagingSenderId: "622055075472",
  appId: "1:622055075472:web:d2b354cd6df00233cd3215",
  measurementId: "G-QV1TP23L6X"
}


// Initialize Firebase
export const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
export const db = getFirestore(app)
