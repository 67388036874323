import Drawer from 'react-modern-drawer'
import {useMediaQuery} from 'react-responsive'

import 'react-modern-drawer/dist/index.css'
import Input from "../../components/Input.js"
import Button from "../../components/Button.js"
import {useForm} from "react-hook-form"
import {useEffect} from "react"

const AddDrawer = (props) => {
  const {isOpen, setIsOpen, onOk} = props
  const {register, handleSubmit, reset, formState: {errors}} = useForm()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  useEffect(() => {
    if (isOpen)
      reset({email: '', displayName: '', phoneNumber: '', password: ''})
  }, [isOpen])

  return <Drawer
    open={isOpen}
    onClose={toggleDrawer}
    direction={isTabletOrMobile ? 'bottom' : 'right'}
    className='flex flex-col py-12 p-2'
    size={isTabletOrMobile ? '50vh' :'30vw'}
  >
    <form onSubmit={handleSubmit((data) => onOk(data))} autoComplete="off">
      <Input label={'Name'} type={'text'} placeholder={'John Doe'} register={register} name={'displayName'} params={{required: true}}/>
      <Input label={'Email'} type={'email'} placeholder={'email@example.com'} className={'mt-2'} register={register} name={'email'} params={{required: true}}/>
      <Input label={'Phone Number'} type={'tel'} placeholder={'972-972-0972'} className={'mt-2'} register={register} name={'phoneNumber'} params={{required: true}}/>
      <Input label={'Password'} type={'text'} placeholder={'Enter Here...'} className={'mt-2'} register={register} name={'password'} params={{required: true}}/>
      <div className={'text-red-600 text-center mt-4'}>{''}</div>
      <div className={'mt-6 flex justify-evenly'}>
        <input type={'submit'} className={'w-1/3 py-2 font-bold rounded-full bg-black text-primary'} value={'Add'}/>
        <Button type={'primary'} className={' w-1/3'} onClick={toggleDrawer}>Cancel</Button>
      </div>
    </form>
  </Drawer>
}

export default AddDrawer
