const Input = (props) => {
  return <div className={'flex flex-col w-full ' + (props.className || '')}>
    <label className={'text-gray-500'}>{props.label} {props.params?.required ? <span className={'text-primary'}>*</span>: ''}</label>
    <input type={props.type} placeholder={props.placeholder} {...props?.register(props.name, props.params)} pattern={props.params?.pattern}/>
  </div>
}

export const PureInput = (props) => {
  return <div className={'flex flex-col w-full ' + (props.className || '')}>
    <label className={'text-gray-500'}>{props.label} {props.params?.required ? <span className={'text-primary'}>*</span>: ''}</label>
    <input type={props.type} placeholder={props.placeholder} value={props.value} onChange={props.onChange} disabled={props.disabled}/>
  </div>
}

export default Input
