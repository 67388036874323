import AuthTemplate from "../../components/AuthTemplate.js"
import Input from "../../components/Input.js"
import Button from "../../components/Button.js"
import {useNavigate} from 'react-router-dom'
import {useAuth} from "../../hooks/useAuth.js"
import {useState} from "react"
import {useForm} from "react-hook-form"

const Login = () => {
  const navigate = useNavigate()
  const {user, login} = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const {register, handleSubmit, formState: {errors}} = useForm()

  const trySignIn = async (data) => {
    if (!loading) {
      setLoading(true)
      let _err = await login(data)
      if (_err) {
        setError(_err)
      }
      setLoading(false)
    }
  }

  return <AuthTemplate
    titles={['GIVE YOUR POSTS THE', 'BOOST THEY NEED.']}
    descriptions={['Your users, your proxies, our tool.', 'The result? unlimited Upvote Potential.']}
  >
    <form onSubmit={handleSubmit((data) => trySignIn(data))}>
      <Input label={'Email'} type={'email'} placeholder={'email@example.com'} className={'mt-2'} register={register} name={'email'} params={{required: true}}/>
      <Input label={'Password'} type={'password'} placeholder={'Enter Here...'} className={'mt-2'} register={register} name={'password'} params={{required: true}}/>
      <div className={'text-red-600 text-center mt-4'}>{error}</div>
      <div className={'mt-6 flex justify-center'}>
        <input type={'submit'} className={'w-1/2 py-2 font-bold rounded-full bg-black text-primary'} value={'SIGN IN'}/>
      </div>
    </form>
    <div className={'mt-4 flex justify-center text-gray-500'}>
      - OR -
    </div>
    <div className={'mt-4 flex justify-center'}>
      <Button type={'primary'} onClick={() => navigate('/signup')}>SIGN UP</Button>
    </div>
    <div className={'mt-4 flex justify-center text-gray-500 cursor-pointer'} onClick={() => navigate('/forgot-password')}>
      Forgot Password?
    </div>
  </AuthTemplate>
}

export default Login
