import AuthTemplate from "../../components/AuthTemplate.js"
import Input from "../../components/Input.js"
import Button from "../../components/Button.js"
import {useNavigate} from 'react-router-dom'
import {useAuth} from "../../hooks/useAuth.js"
import {useForm} from 'react-hook-form'
import {useState} from "react"

const Signup = () => {
  const navigate = useNavigate()
  const {signup} = useAuth()
  const [error, setError] = useState('')

  const {register, handleSubmit, formState: {errors}} = useForm()

  const trySignUp = async (data) => {
    if (data.password !== data['password-confirm']) {
      return setError('Passwords are not matched.')
    }
    if (data.password.length < 6) {
      return setError('Passwords should have 6 or more characters.')
    }

    signup(data)
    setError('')
  }

  return <AuthTemplate
    titles={["IT'S ABOUT TIME", "YOU JOINED."]}
    descriptions={['Future you thanks you.']}
  >
    <form onSubmit={handleSubmit((data) => trySignUp(data))}>
      <Input label={'Name'} type={'text'} placeholder={'John Doe'} register={register} name={'displayName'} params={{required: true}}/>
      <Input label={'Email'} type={'email'} placeholder={'email@example.com'} className={'mt-2'} register={register} name={'email'} params={{required: true}}/>
      <Input label={'Phone Number'} type={'tel'} placeholder={'972-972-0972'} className={'mt-2'} register={register} name={'phoneNumber'} params={{required: true}}/>
      <Input label={'New Password'} type={'password'} placeholder={'Enter Here...'} className={'mt-2'} register={register} name={'password'} params={{required: true}}/>
      <Input label={'Repeat Password'} type={'password'} placeholder={'Repeat Here...'} className={'mt-2'} register={register} name={'password-confirm'} params={{required: true}}/>
      <div className={'text-red-600 text-center mt-4'}>{error}</div>
      <div className={'mt-6 flex justify-center'}>
        <input type={'submit'} className={'w-1/2 py-2 font-bold rounded-full bg-black text-primary'} value={'SIGN UP'}/>
      </div>
    </form>
    <div className={'mt-4 flex justify-center text-gray-500'}>
      - OR -
    </div>
    <div className={'mt-4 flex justify-center'}>
      <Button type={'primary'} onClick={() => navigate('/login')}>SIGN IN</Button>
    </div>
  </AuthTemplate>
}

export default Signup
