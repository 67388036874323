import Layout from "../../components/Layout.js"
import {useNavigate, useLocation} from "react-router-dom"
import routes from "../../routes"
import {PureInput} from "../../components/Input.js"
import Button from "../../components/Button.js"
import {useEffect, useState} from "react"
import Accounts from "../../models/Accounts.js"
import useBackendApi from "../../services/backend.js"
import {useAuth} from "../../hooks/useAuth.js"

const queryString = require('query-string')

const EditUser = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const Api = useBackendApi()
  const {user} = useAuth()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const params = queryString.parse(location.search)
  const id = params.id

  const onReturn = () => {
    navigate(routes.Users.url)
  }

  const updateUser = async () => {
    if (id && username && password && !loading) {
      setLoading(true)
      const res = await Accounts.updateById(id, {
        username,
        password,
        status: '-',
        updatedAt: Date.now()
      })
      Api.validateAccounts({uid: user.uid})
      setLoading(false)

      if (res) {
        onReturn()
      }
    }
  }

  const deleteUser = async () => {
    if (id && !loading) {
      setLoading(true)
      const res = await Accounts.deleteById(id)
      setLoading(false)

      if (res) {
        onReturn()
      }
    }
  }

  const subscribeUser = async () => {
    if (!loading) {
      setLoading(true)
      const _account = await Accounts.getBydId(id)
      if (_account) {
        setUsername(_account.username)
        setPassword(_account.password)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      subscribeUser()
    }
  }, [])

  return <Layout>
    <div className={'grid grid-cols-2 sm:h-full pb-10 gap-y-2'}>
      <div className={'col-span-2 sm:col-span-1 sm:border-r-2 border-black sm:pr-6 grid grid-rows-8'}>
        <div className={'row-span-1'}>
          <button className={'bg-primary text-white py-2 px-4 rounded-full font-bold cursor-pointer'} onClick={onReturn}>&#8634; Return</button>
        </div>
        <div className={'row-span-6'}>
          <PureInput
            label={'Username'} type={'text'} placeholder={''} className={'mt-2'} name={'username'} params={{required: true, disabled: loading}}
            value={username} onChange={e => setUsername(e.target.value)}
          />
          <PureInput
            label={'Password'} type={'text'} placeholder={''} className={'mt-2'} name={'password'} params={{required: true, disabled: loading}}
            value={password} onChange={e => setPassword(e.target.value)}
          />
          <div className={'mt-6 flex justify-center'}>
            <Button type={`secondary`} onClick={updateUser}>UPDATE USER</Button>
          </div>
          <div className={'mt-4 flex justify-center text-gray-500'}>
            - OR -
          </div>
          <div className={'mt-4 flex justify-center'}>
            <Button type={'primary'} onClick={deleteUser}>DELETE USER</Button>
          </div>
        </div>
      </div>
      <div className={'col-span-2 sm:col-span-1 sm:pl-6 grid grid-rows-8 pt-4'}>
        <div className={'row-span-1 text-center text-2xl'}>
          Things To Note:
        </div>
        <div className={'row-span-6'}>
          1. Once you delete an account, we cannot recover any information. <br/>
          2. When a user is updated, we will attempt to verify its eligibility by attempting to login on Reddit. <br/>
          <b>We will attempt to use a random proxy from your list. If none are found, we can not confirm active status even if the account still works.</b><br/>
          3. We do not recommend using personal or private passwords with your accounts. This app is not designed to protect them due to their Expendability when utilizing such features.
        </div>
      </div>
    </div>
  </Layout>
}

export default EditUser
