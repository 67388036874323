import { createSlice } from '@reduxjs/toolkit'
import {fetchUpvoteLogs} from "../thunks/HomeThunk.js"


export const HomeSlice = createSlice({
  name: 'home',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: {
    logs: [],
    url: '',
    count: 1,
    countPerMin: 5,
    rotateProxy: false,
    pending: false,
    loading: false
  },
  reducers: {
    setInputData: (state, {payload}) => {
      state.url = payload?.url || ''
      state.count = payload?.count || 1
      state.countPerMin = payload?.countPerMin || 5
      state.rotateProxy = payload?.rotateProxy || false
    }
  },
  extraReducers: {
    [fetchUpvoteLogs.pending]: (state) => {
      state.loading = true
    },
    [fetchUpvoteLogs.fulfilled]: (state, {payload}) => {
      state.logs = payload.upvote_logs
      state.pending = payload.pending
      state.loading = false
    },
    [fetchUpvoteLogs.rejected]: (state) => {
      state.loading = false
    }
  }
})

export const {setInputData} = HomeSlice.actions

export const HomeSelector = (state) => state.home

export default HomeSlice.reducer
