import {useNavigate} from 'react-router-dom'
import Layout from "../../components/Layout.js"
import routes from "../../routes"
import Accounts from '../../models/Accounts.js'
import {useEffect, useState} from "react"
import {useAuth} from "../../hooks/useAuth.js"

const Users = () => {
  const navigate = useNavigate()
  const {user} = useAuth()
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)

  const onAdd = () => {
    navigate(routes.UserAdd.url)
  }

  const onEdit = (id) => {
    navigate(`${routes.UserEdit.url}?id=${id}`)
  }

  const subscribeAccounts = async () => {
    if (user && user.uid && !loading) {
      setLoading(true)
      const accounts = await Accounts.getAccountsByUserId(user.uid)
      setRows(accounts)
      setLoading(false)
    }
  }

  useEffect(() => {
    subscribeAccounts()
    let interval = setInterval(() => {
      subscribeAccounts()
    }, 30 * 1000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return <Layout>
    <div className={'mb-3'}>
      <button className={'bg-primary text-white py-2 px-4 rounded-full font-bold cursor-pointer'} onClick={onAdd}>+ Add New</button>
    </div>
    <div className={'overflow-x-auto flex flex-col min-w-min'}>
      <div className={'grid grid-cols-7 w-full gap-4 font-bold w-full border-b-2 border-black'}>
        <div className={'col-span-2 sm:col-span-1'}>User</div>
        <div className={'hidden sm:block col-span-2'}>Date Added</div>
        <div className={'hidden sm:block col-span-2'}>Date Modified</div>
        <div className={'col-span-2 sm:col-span-1'}>Status</div>
        <div className={'col-span-2 sm:col-span-1'}/>
      </div>
      <div className={'overflow-y-auto h-table'}>
        {rows.map((it, id) => {
          return <div className={'grid grid-cols-7 w-full gap-4 w-full mt-2'} key={'user-' + id}>
            <div className={'col-span-2 sm:col-span-1 truncate'}>{it.username}</div>
            <div className={'hidden sm:block col-span-2'}>{new Date(it.createdAt).toLocaleString()}</div>
            <div className={'hidden sm:block col-span-2'}>{new Date(it.updatedAt).toLocaleString()}</div>
            <div className={'col-span-2 sm:col-span-1 capitalize status-' + it.status}>{it.status}</div>
            <div className={'col-span-2 sm:col-span-1'}>
              <button className={'bg-primary text-white px-8 cursor-pointer rounded-full font-bold'} onClick={() => onEdit(it.id)}>Edit</button>
            </div>
          </div>
        })}
      </div>
    </div>
    <div className={'flex flex-row justify-end mt-2 border-t border-black'}>
      <div className={'text-gray-500 text-sm italic'}>{rows.length} users</div>
    </div>
  </Layout>
}

export default Users
