import Layout from "../../components/Layout.js"
import {useNavigate} from "react-router-dom"
import routes from "../../routes"
import {useForm} from "react-hook-form"
import Input from "../../components/Input.js"
import CSVUploader from "../../components/CSVUploader.js"
import {useState} from "react"
import DataDrawer from "../../components/DataDrawer.js"
import Proxies from "../../models/Proxies.js"
import {useAuth} from "../../hooks/useAuth.js"
import parseProxy from "@devhigley/parse-proxy"

const AddProxy = () => {
  const navigate = useNavigate()
  const {user} = useAuth()

  const {register, handleSubmit, formState: {errors}} = useForm()
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])

  const onReturn = () => {
    navigate(routes.Proxies.url)
  }

  const onFileChange = (csv_data) => {
    try {
      const {data, errors, meta} = csv_data
      if (data.length > 0) {
        let _data = data
        if (errors.length > 0) {
          if (data[0].length === 1) {
            // filter empty records
            _data = data.filter(it => it[0] !== '')

            // http://user-sp85542933-sessionduration-1:AL^1vZ1387qd@us.smartproxy.com:10001
            if (_data[0][0].startsWith('http')) {
              _data = _data.map(it => {
                const parsed = parseProxy(it.join(''))[0]
                console.log(parsed)
                return [
                  parsed.host,
                  parsed.port,
                  parsed.auth ? parsed.auth.username : '',
                  parsed.auth ? parsed.auth.password : ''
                ]
              })
            } else {
              // us.smartproxy.com:10001:user-sp85542933-sessionduration-1:AL^1vZ1387qd
              _data = _data.map(it => it[0].split(":"))
            }
          }
        }
        setRows(_data)
        setDrawerOpen(true)
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  const _addOne = async (data) => await Proxies.add({
    ...data,
    status: 'inactive',
    user_id: user.uid,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  })

  const addProxy = async (data) => {
    if (!loading) {
      setLoading(true)
      const res = await _addOne(data)
      setLoading(false)
      if (res)
        onReturn()
    }
  }

  const importProxies = async (proxies) => {
    if (!loading) {
      setLoading(true)
      for (const proxy of proxies) {
        await _addOne({
          ip: proxy[0],
          port: proxy[1],
          username: proxy.length > 2 ? proxy[2] : '',
          password: proxy.length > 3 ? proxy[3] : ''
        })
      }
      setLoading(false)
      onReturn()
    }
  }

  return <Layout>
    <div className={'grid grid-cols-2 sm:h-full pb-10 gap-y-2'}>
      <div className={'col-span-2 sm:col-span-1 sm:border-r-2 border-black sm:pr-6 grid grid-rows-8'}>
        <div className={'row-span-1'}>
          <button className={'bg-primary text-white py-2 px-4 rounded-full font-bold cursor-pointer'} onClick={onReturn}>&#8634; Return</button>
        </div>
        <div className={'row-span-6'}>
          <form onSubmit={handleSubmit(data => addProxy(data))} autoComplete="off">
            <Input label={'IP'} type={'text'} placeholder={''} className={'mt-2'} register={register} name={'ip'} params={{required: true}}/>
            <Input label={'Port'} type={'number'} placeholder={''} className={'mt-2'} register={register} name={'port'} params={{required: true}}/>
            <div className={'mt-6 flex justify-center'}>
              <input type={'submit'} className={'w-full sm:w-1/2 py-2 font-bold rounded-full bg-black text-primary'} value={'ADD NEW PROXY'}/>
            </div>
          </form>
        </div>
      </div>
      <div className={'col-span-2 sm:col-span-1 sm:pl-6 grid grid-rows-8 pt-4'}>
        <div className={'row-span-1'}>
        </div>
        <div className={'row-span-6'}>
          <CSVUploader onChange={onFileChange}/>
          <div className={'text-center mt-4'}>Upload a .csv file to add multiple proxies at once.</div>
        </div>
      </div>
    </div>
    <DataDrawer isOpen={isDrawerOpen} setIsOpen={setDrawerOpen} data={rows} onOk={importProxies}/>
  </Layout>
}

export default AddProxy
