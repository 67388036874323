import './App.scss';
import {BrowserRouter} from 'react-router-dom'
import {AuthProvider} from "./hooks/useAuth.js"
import {AppRoutes} from './routes'
import 'react-toastify/dist/ReactToastify.css'
import {ToastContainer} from "react-toastify"
import {persistedStore, store} from "./redux/store"
import {Provider as StoreProvider} from 'react-redux'
import {PersistGate} from "redux-persist/integration/react"

function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate persistor={persistedStore}>
        <BrowserRouter>
          <AuthProvider>
            <ToastContainer
              position={'bottom-center'}
              autoClose={5000}
            />
            <AppRoutes/>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
