import {useAuth} from "../hooks/useAuth.js"
import {Navigate} from 'react-router-dom'
import Logo from "./Logo.js"

const AuthTemplate = ({titles, descriptions, children}) => {
  const {user, setUser} = useAuth()

  if (user && user.stsTokenManager) {
    if (user.stsTokenManager.expirationTime > Date.now()) {
      return <Navigate to={'/'}/>
    } else {
      // setUser(null)
    }
  }

  return <div className={'grid grid-cols-4 h-screen gap-y-4'}>
    <div className={'relative bg-primary col-span-4 md:col-span-3 p-4 sm:p-10 flex flex-col justify-center items-start font-bold'}>
      <div className={'text-5xl'}>
        {titles.map((it, id) => <div key={'title-' + id}>{it}</div>)}
      </div>
      <div className={'text-xl mt-6'}>
        {descriptions.map((it, id) => <div className={''} key={'desc-' + id}>{it}</div>)}
      </div>
      <div className={'hidden sm:block absolute bottom-2 sm:bottom-10 text-xs w-7/12'}>This application is not correlated to or a product of Reddit, Inc. For account testing purpose only.</div>
    </div>
    <div className={'col-span-4 md:col-span-1 px-2 py-12 flex flex-col justify-between'}>
      <Logo/>
      <div className={'text-sm h-3/4 flex flex-col justify-center'}>
        {children}
      </div>
    </div>
  </div>
}

export default AuthTemplate
