import {findRouteByPath} from "../routes"
import {useLocation} from 'react-router-dom'
import SideBar from "./SideBar.js"
import Logo from "./Logo.js"
import {useAuth} from "../hooks/useAuth.js"

const Layout = (props) => {
  const location = useLocation()
  const currentRoute = findRouteByPath(location.pathname)
  const {user} = useAuth()

  return <div className={'grid grid-cols-8 h-screen flex flex-row'}>
    <div className={'col-span-2 sm:col-span-1 bg-black'}>
      <SideBar current={currentRoute}/>
    </div>
    <div className={'col-span-6 sm:col-span-7 px-6 grid grid-rows-6'}>
      <div className={'row-span-1 flex justify-between items-center'}>
        <div className={'text-xs sm:text-4xl font-bold'}>{currentRoute.title}</div>
        <Logo/>
      </div>
      <div className={'row-span-5 text-xs sm:text-sm xl:text-xl'}>
        {user.disabled ? 'Your account was disabled by Admin.' : props.children}
      </div>
    </div>
  </div>
}

export default Layout
