import {useAuth} from "../hooks/useAuth.js"
import {Navigate, Route, Routes} from "react-router-dom"
import Login from "../pages/Login"
import Signup from "../pages/Signup"
import ForgotPassword from "../pages/ForgotPassword"
import Home from "../pages/Home"
import Users from "../pages/Users"
import AddUser from "../pages/Users/AddUser.js"
import EditUser from "../pages/Users/EditUser.js"
import Proxies from "../pages/Proxies"
import AddProxy from "../pages/Proxies/AddProxy.js"
import EditProxy from "../pages/Proxies/EditProxy.js"
import Subscription from "../pages/Subscription"
import ManageAccess from "../pages/ManageAccess"

const routes = {
  LogIn: {
    url: '/login',
    isPublic: true
  },
  SignUp: {
    url: '/signup',
    isPublic: true
  },
  ForgotPassword: {
    url: '/forgot-password',
    isPublic: true
  },
  Home: {
    url: '/',
    title: 'Home',
    isPublic: false
  },
  Users: {
    url: '/users',
    title: 'Users',
    isPublic: false
  },
  UserAdd: {
    url: '/users/add',
    title: 'Add New User',
    isPublic: false
  },
  UserEdit: {
    url: '/users/edit',
    title: 'Edit Existing User',
    isPublic: false
  },
  Proxies: {
    url: '/proxies',
    title: 'Proxies',
    isPublic: false,
  },
  ProxyAdd: {
    url: '/proxies/add',
    title: 'Add New Proxy',
    isPublic: false,
  },
  ProxyEdit: {
    url: '/proxies/edit',
    title: 'Edit Existing Proxies',
    isPublic: false
  },
  Subscription: {
    url: '/subscription',
    title: 'Subscription',
    isPublic: false
  },
  ManageAccess: {
    url: '/manage-access',
    title: 'Manage Access',
    isPublic: false
  }
}

export const ProtectedRoute = ({children}) => {
  const {user, logout} = useAuth()
  if (!user || !user.stsTokenManager || user.stsTokenManager.expirationTime < Date.now()) {
    logout()
    return <Navigate to={'/login'}/>
  }

  return children
}

export const findRouteByPath = (path) => {
  for (let route in routes) {
    if (path === routes[route].url) {
      return routes[route]
    }
  }
  return null
}

export const useSidebarRoutes = () => {
  const {user} = useAuth()

  const sidebarRoutes = [
    routes.Home,
    routes.Users,
    routes.Proxies,
    // routes.Subscription,
  ]

  if (user.isAdmin) {
    sidebarRoutes.push(routes.ManageAccess)
  }

  return sidebarRoutes
}

export const AppRoutes = () => {
  const {user} = useAuth()

  return (
    <Routes>
      <Route path={'*'} element={<Navigate to={routes.LogIn.url} replace/>}/>
      <Route path={routes.LogIn.url} element={<Login/>}/>
      <Route path={routes.SignUp.url} element={<Signup/>}/>
      <Route path={routes.ForgotPassword.url} element={<ForgotPassword/>}/>
      <Route path={routes.Home.url} element={<ProtectedRoute><Home/></ProtectedRoute>}/>
      <Route path={routes.Users.url} element={<ProtectedRoute><Users/></ProtectedRoute>}/>
      <Route path={routes.UserAdd.url} element={<ProtectedRoute><AddUser/></ProtectedRoute>}/>
      <Route path={routes.UserEdit.url} element={<ProtectedRoute><EditUser/></ProtectedRoute>}/>
      <Route path={routes.Proxies.url} element={<ProtectedRoute><Proxies/></ProtectedRoute>}/>
      <Route path={routes.ProxyAdd.url} element={<ProtectedRoute><AddProxy/></ProtectedRoute>}/>
      <Route path={routes.ProxyEdit.url} element={<ProtectedRoute><EditProxy/></ProtectedRoute>}/>
      <Route path={routes.Subscription.url} element={<ProtectedRoute><Subscription/></ProtectedRoute>}/>
      {user?.isAdmin && <Route path={routes.ManageAccess.url} element={<ProtectedRoute><ManageAccess/></ProtectedRoute>}/>}
    </Routes>
  )
}

export default routes
