import {createAsyncThunk} from "@reduxjs/toolkit"
import ACTION from "../actions.js"
import {toast} from "react-toastify"
import Logs from "../../models/Logs.js"

export const fetchUpvoteLogs = createAsyncThunk(
  ACTION.FETCH_UPVOTE_LOGS,
  async (user_id) => {
    try {
      const res = await Logs.getBydId(user_id)
      return {
        upvote_logs: res?.upvote_logs?.sort((a, b) => a < b ? 1 : -1) || [],
        pending: res?.pending || false
      }
    } catch (e) {
      toast.error(`Can't get upvote logs: ${e.message}`)
    }
  }
)
