import Layout from "../../components/Layout.js"
import {useForm} from "react-hook-form"
import Input from "../../components/Input.js"
import useBackendApi from "../../services/backend.js"
import {useEffect, useState} from "react"
import {useAuth} from "../../hooks/useAuth.js"
import LoadingOverlay from "react-loading-overlay-ts"
import {toast} from "react-toastify"
import {useDispatch, useSelector} from "react-redux"
import {HomeSelector, setInputData} from "../../redux/slices/HomeSlice.js"
import {fetchUpvoteLogs} from "../../redux/thunks/HomeThunk.js"
import {yupResolver} from "@hookform/resolvers/yup"
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  url: Yup.string().required('Url is required'),
  count: Yup.number().required('Count is required')
})

const Home = () => {
  const {register, handleSubmit, reset, formState: {errors}} = useForm({
    resolver: yupResolver(validationSchema)
  })
  const [loading, setLoading] = useState(false)
  const {logs, url, count, countPerMin, pending, rotateProxy} = useSelector(HomeSelector)
  const dispatch = useDispatch()
  const backendApi = useBackendApi()
  const {user} = useAuth()

  useEffect(() => {
    reset({
      count, url, countPerMin, rotateProxy
    })
  }, [count, url])

  const submitUpvote = (data) => {
    // console.log(data)
    if (!loading) {
      setLoading(true)
      dispatch(setInputData(data))
      backendApi.upvote(data)
        .then(res => {
          // console.log(res.data)
          const message = res.data?.message || 'Requested'
          if (res.ok) {
            toast.success(message)
          } else {
            toast.error(message)
          }
        })
        .catch(e => {
          toast.error(e.message)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const subscribeLogs = () => {
    dispatch(fetchUpvoteLogs(user.uid))
  }

  useEffect(() => {
    subscribeLogs()
    let interval = setInterval(() => {
      subscribeLogs()
    }, 5 * 1000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return <Layout>
    <div className={'px-2 sm:px-20 flex flex-col'}>
      <div className={'flex items-center h-32 m-auto'}>
        <LoadingOverlay
          active={loading}
          spinner
          text={'Your request is being processed...'}
        >
          <form onSubmit={handleSubmit(data => submitUpvote(data))} className={'sm:w-full p-2'}>
            <div className={'grid grid-cols-6 gap-x-4 font-bold'}>
              <div className={'col-span-6 sm:col-span-4 flex flex-col items-center justify-center'}>
                <Input label={'Enter Link to Post Here'} type={'text'} placeholder={'https://www.reddit.com/r/readdit/comments/abc123'} className={'mt-2'} register={register} name={'url'} params={{required: true}}/>
              </div>
              <div className={'col-span-6 sm:col-span-1'}>
                <Input label={'# of Upvotes'} type={'number'} placeholder={'1'} className={'mt-2'} register={register} name={'count'} params={{required: true}}/>
              </div>
              <div className={'col-span-6 sm:col-span-1'}>
                <Input label={'# per Min'} type={'number'} placeholder={'5'} className={'mt-2'} register={register} name={'countPerMin'} params={{required: true}}/>
              </div>
              <div className={'col-span-6 sm:col-span-5 flex flex-row items-center justify-center mt-4 sm:gap-x-10'}>
                <div className={'flex justify-center items-center gap-x-2 py-2'}>
                  <input type={'checkbox'} className={''} {...register('rotateProxy')}/>
                  <div>Rotate Proxy</div>
                </div>
                <input type={'submit'} value={'Submit Upvotes'} className={'uppercase rounded-full w-full sm:w-1/2 bg-black text-primary py-2 cursor-pointer'}/>
              </div>
            </div>
          </form>
        </LoadingOverlay>
      </div>
      <div className={'pb-4 h-64 sm:h-96 w-48 sm:w-full flex flex-col m-auto mt-20 sm:mt-10'}>
        <div className={'text-sm bg-black text-gray-500 rounded sm:rounded-xl p-2 font-mono overflow-y-auto overflow-x-auto min-h-full'}>
          {logs.map((it, id) => <div key={'log-' + id} className={` whitespace-nowrap status-${it.type}`}>[{new Date(it.timestamp).toLocaleString()}] -> {it.message}</div>)}
        </div>
      </div>
    </div>
  </Layout>
}

export default Home
