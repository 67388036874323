import { createSlice } from '@reduxjs/toolkit'


export const ProfileSlice = createSlice({
  name: 'profile',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: {
    name: '',
    phone: ''
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload
    }
  },
})

export const {setName} = ProfileSlice.actions
export default ProfileSlice.reducer
