import {useSidebarRoutes} from "../routes"
import {useNavigate} from 'react-router-dom'
import {useAuth} from "../hooks/useAuth.js"

const SideBar = (props) => {
  const NavRoutes = useSidebarRoutes()
  const navigate = useNavigate()
  const isActive = (title) => (title === 'Home' && props.current?.url === '/')
    || props.current?.url?.indexOf(title.replace(/ /g, '-').toLowerCase()) > -1
    || false
  const {logout} = useAuth()

  return <div className={'flex flex-col text-white font-bold text-xs sm:text-xl gap-y-6 mt-40 p-2 sm:p-6'}>
    {NavRoutes.map((it, id) => <div
      className={`cursor-pointer ${isActive(it.title) ? ' text-primary' : ''}`} key={'nav-' + id}
      onClick={() => navigate(it.url)}
    >
      {it.title}
    </div>)}
    <div
      className={`cursor-pointer`} key={'nav-logout'}
      onClick={logout}
    >
      Log Off
    </div>
  </div>
}

export default SideBar
