import {useAuth} from "../hooks/useAuth.js"
import {create} from 'apisauce'

const useBackendApi = () => {
  const {user, logout} = useAuth()
  if (!user || !user.stsTokenManager || user.stsTokenManager.expirationTime < Date.now()) {
    logout()
  }


  const api = create({
    // baseURL: 'http://127.0.0.1:8080/api',
    baseURL: 'https://a9da-34-209-91-241.ngrok.io/api',
    headers: {
      'x-auth': user?.stsTokenManager?.accessToken || '',
      'Content-Type': 'application/json'
    }
  })

  const checkStatus = () => api.get('/test')

  const upvote = ({url, count, countPerMin, rotateProxy}) => api.post('/upvote', {
    url, count, countPerMin, rotateProxy
  })

  const validateAccounts = ({uid}) => api.post('/accounts/validate', {uid})

  const getAllUsers = () => api.get('/users')
  const addNewUser = (data) => api.put('/users', data)
  const setUserDisabled = ({uid, disabled}) => api.post('/users', {uid, disabled})
  const deleteUser = ({uid}) => api.delete('/users/' + uid)

  return {
    checkStatus,
    upvote,
    getAllUsers,
    addNewUser,
    setUserDisabled,
    deleteUser,
    validateAccounts,
  }
}

export default useBackendApi
