import {createContext, useContext, useMemo, useState} from "react"
import {useNavigate} from "react-router-dom"
import {useLocalStorage} from "./useLocalStorage"
import {
  getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut,
  updateProfile
} from 'firebase/auth'
import {app} from '../firebase.js'
import Profile from "../models/Profile.js"

const auth = getAuth(app)

const AuthContext = createContext()

export const AuthProvider = ({children}) => {
  const [user, setUser] = useLocalStorage("user", null)
  const navigate = useNavigate()

  // call this function when you want to authenticate the user
  const login = async ({email, password}) => {
    let ret = null
    try {
      await signInWithEmailAndPassword(auth, email, password)
      const _user = auth.currentUser
      const profile = await Profile.getBydId(email)
      const phoneNumber = profile?.phoneNumber || ''
      const isAdmin = profile?.isAdmin || false
      setUser({..._user, phoneNumber, isAdmin})
      navigate("/")
    } catch (error) {
      console.log(error.code, error.message)
      if (error.code === 'auth/user-not-found')
        ret = 'User not found'
      else
        ret = error.message
    }
    return ret
  }

  const signup = async ({email, password, displayName, phoneNumber}) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password)
      const _user = auth.currentUser
      await updateProfile(_user, {
        displayName,
      })
      await Profile.setById(email, {phoneNumber, isAdmin: false})
      setUser(auth.currentUser)
      navigate("/")
    } catch (error) {
      console.log(error.code, error.message)
    }
  }

  // call this function to sign out logged in user
  const logout = async () => {
    try {
      await signOut(auth)
      setUser(null)
      navigate("/login", {replace: true})
    } catch (e) {

    }
  }

  const value = useMemo(
    () => ({
      user, setUser,
      signup,
      login,
      logout
    }),
    [user]
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
