import Layout from "../../components/Layout.js"
import {useNavigate, useLocation} from "react-router-dom"
import routes from "../../routes"
import {PureInput} from "../../components/Input.js"
import Button from "../../components/Button.js"
import {useEffect, useState} from "react"
import Proxies from "../../models/Proxies.js"

const queryString = require('query-string')

const EditProxy = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [ip, setIp] = useState('')
  const [port, setPort] = useState('')
  const [loading, setLoading] = useState(false)

  const params = queryString.parse(location.search)
  const id = params.id

  const onReturn = () => {
    navigate(routes.Proxies.url)
  }

  const updateProxy = async () => {
    if (id && ip && port && !loading) {
      setLoading(true)
      const res = await Proxies.updateById(id, {
        ip,
        port,
        status: 'inactive',
        updatedAt: Date.now()
      })
      setLoading(false)

      if (res) {
        onReturn()
      }
    }
  }

  const deleteProxy = async () => {
    if (id && !loading) {
      setLoading(true)
      const res = await Proxies.deleteById(id)
      setLoading(false)

      if (res) {
        onReturn()
      }
    }
  }

  const subscribeProxy = async () => {
    if (!loading) {
      setLoading(true)
      const _proxy = await Proxies.getBydId(id)
      if (_proxy) {
        setIp(_proxy.ip)
        setPort(_proxy.port)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      subscribeProxy()
    }
  }, [])

  return <Layout>
    <div className={'grid grid-cols-2 sm:h-full pb-10 gap-y-2'}>
      <div className={'col-span-2 sm:col-span-1 sm:border-r-2 border-black sm:pr-6 grid grid-rows-8'}>
        <div className={'row-span-1'}>
          <button className={'bg-primary text-white py-2 px-4 rounded-full font-bold cursor-pointer'} onClick={onReturn}>&#8634; Return</button>
        </div>
        <div className={'row-span-6'}>
          <PureInput
            label={'IP'} type={'text'} placeholder={''} className={'mt-2'} name={'ip'} params={{required: true, disabled: loading}}
            value={ip} onChange={e => setIp(e.target.value)}
          />
          <PureInput
            label={'Port'} type={'text'} placeholder={''} className={'mt-2'} name={'port'} params={{required: true, disabled: loading}}
            value={port} onChange={e => setPort(e.target.value)}
          />
          <div className={'mt-6 flex justify-center'}>
            <Button type={`secondary`} onClick={updateProxy}>UPDATE PROXY</Button>
          </div>
          <div className={'mt-4 flex justify-center text-gray-500'}>
            - OR -
          </div>
          <div className={'mt-4 flex justify-center'}>
            <Button type={'primary'} onClick={deleteProxy}>DELETE PROXY</Button>
          </div>
        </div>
      </div>
      <div className={'col-span-2 sm:col-span-1 sm:pl-6 grid grid-rows-8 pt-4'}>
        <div className={'row-span-1 text-center text-2xl'}>
          Things To Note:
        </div>
        <div className={'row-span-6'}>
          1. Once you delete a proxy, we cannot recover any information. <br/>
          2. <b>Please ensure your proxy is active and working. If there is an issue with one of your proxies, it may lower the number of upvotes possible.</b>
        </div>
      </div>
    </div>
  </Layout>
}

export default EditProxy
