const ACTION = {
  FETCH_UPVOTE_LOGS: 'FETCH_UPVOTE_LOGS',
  FETCH_ACCOUNTS: 'FETCH_ACCOUNTS',
  FETCH_PROXIES: 'FETCH_PROXIES',

  IMPORT_ACCOUNTS: 'IMPORT_ACCOUNTS',
  IMPORT_PROXIES: 'IMPORT_PROXIES',

  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  UPDATE_PROXY: 'UPDATE_PROXY',

  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  DELETE_PROXY: 'DELETE_PROXY',

  START_UPVOTE: 'START_UPVOTE'
}

export default ACTION
