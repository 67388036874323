import Layout from "../../components/Layout.js"
import {useNavigate} from "react-router-dom"
import routes from "../../routes"
import {useForm} from "react-hook-form"
import Input from "../../components/Input.js"
import CSVUploader from "../../components/CSVUploader.js"
import {useState} from "react"
import DataDrawer from "../../components/DataDrawer.js"
import Accounts from "../../models/Accounts.js"
import {useAuth} from "../../hooks/useAuth.js"
import useBackendApi from "../../services/backend.js"

const AddUser = () => {
  const navigate = useNavigate()
  const {user} = useAuth()
  const Api = useBackendApi()

  const {register, handleSubmit, formState: {errors}} = useForm()
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])

  const onReturn = () => {
    navigate(routes.Users.url)
  }

  const onFileChange = (csv_data) => {
    try {
      const {data, errors, meta} = csv_data
      if (data.length > 0) {
        setRows(data)
        setDrawerOpen(true)
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  const _addOne = async (data) => await Accounts.add({
    ...data,
    status: '-',
    user_id: user.uid,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  })

  const addUser = async (data) => {
    if (!loading) {
      setLoading(true)
      const res = await _addOne(data)
      Api.validateAccounts({uid: user.uid})
      setLoading(false)
      if (res)
        onReturn()
    }
  }

  const importUsers = async (users) => {
    if (!loading) {
      setLoading(true)
      for (const user of users) {
        await _addOne({
          username: user[0],
          password: user[1]
        })
      }
      Api.validateAccounts({uid: user.uid})
      setLoading(false)
      onReturn()
    }
  }

  return <Layout>
    <div className={'grid grid-cols-2 sm:h-full pb-10 gap-y-2'}>
      <div className={'col-span-2 sm:col-span-1 sm:border-r-2 border-black sm:pr-6 grid grid-rows-8'}>
        <div className={'row-span-1'}>
          <button className={'bg-primary text-white py-2 px-4 rounded-full font-bold cursor-pointer'} onClick={onReturn}>&#8634; Return</button>
        </div>
        <div className={'row-span-6'}>
          <form onSubmit={handleSubmit(data => addUser(data))} autoComplete="off">
            <Input label={'Username'} type={'text'} placeholder={''} className={'mt-2'} register={register} name={'username'} params={{required: true}}/>
            <Input label={'Password'} type={'text'} placeholder={''} className={'mt-2'} register={register} name={'password'} params={{required: true}}/>
            <div className={'mt-6 flex justify-center'}>
              <input type={'submit'} className={'w-full sm:w-1/2 py-2 font-bold rounded-full bg-black text-primary'} value={'ADD NEW USER'}/>
            </div>
          </form>
        </div>
      </div>
      <div className={'col-span-2 sm:col-span-1 sm:pl-6 grid grid-rows-8 pt-4'}>
        <div className={'row-span-1'}>
        </div>
        <div className={'row-span-6'}>
          <CSVUploader onChange={onFileChange}/>
          <div className={'text-center mt-4'}>Upload a .csv file to add multiple users at once.</div>
        </div>
      </div>
    </div>
    <DataDrawer isOpen={isDrawerOpen} setIsOpen={setDrawerOpen} data={rows} onOk={importUsers}/>
  </Layout>
}

export default AddUser
