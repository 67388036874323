import Drawer from 'react-modern-drawer'
import {useMediaQuery} from 'react-responsive'

import 'react-modern-drawer/dist/index.css'
import {useEffect, useState} from "react"
import parseProxy from '@devhigley/parse-proxy'

const DataDrawer = (props) => {
  const {isOpen, setIsOpen, data, onOk} = props

  const [rows, setRows] = useState(data)
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const removeItem = (index) => {
    if (index > -1) {
      const _rows = rows.slice()
      _rows.splice(index, 1)
      setRows(_rows)
    }
  }

  useEffect(() => {
    setRows(data)
  }, [data])

  return <Drawer
    open={isOpen}
    onClose={toggleDrawer}
    direction={isTabletOrMobile ? 'bottom' : 'right'}
    className='flex flex-col py-12 p-2'
    size={isTabletOrMobile ? '40vh' : '30vw'}
  >
    <div className={'font-bold text-md border-b border-black'}>{rows.length} records are found</div>
    <div className={'h-3/4 overflow-y-auto text-sm p-1'}>
      {rows.map((it, id) => <div key={'data-' + id} className={'grid grid-cols-12'}>
        <div className={'col-span-1'}>{id + 1}</div>
        <div className={'col-span-3 truncate'}>{it[0]}</div>
        <div className={'col-span-2 truncate'}>{it[1]}</div>
        {it.length > 2 && <div className={'col-span-2 truncate'}>{it[2]}</div>}
        {it.length > 3 && <div className={'col-span-2 truncate'}>{it[3]}</div>}
        <div className={'col-span-1'}>
          <button className={'text-primary rounded-full'} onClick={() => removeItem(id)}>&#215;</button>
        </div>
      </div>)}
    </div>
    <div className={'flex justify-center gap-x-4 sm:mt-4'}>
      <button className={'px-8 py-1 text-md font-bold rounded-full text-primary bg-black '} onClick={() => onOk(rows)}>Import</button>
      <button className={'px-8 py-1 text-md font-bold rounded-full text-white bg-primary '} onClick={toggleDrawer}>Cancel</button>
    </div>
  </Drawer>
}

export default DataDrawer
