import LogoImg from "../assets/images/logo.png"

const Logo = ({className}) => {
  return <div className={'flex flex-col justify-center items-center '}>
    <img src={LogoImg} alt={'logo'} className={'w-24 sm:w-60'}/>
    <span className={'text-xs sm:text-sm font-bold -mt-2 sm:-mt-4 ml-10 sm:ml-6'}>upvote utility</span>
  </div>
}

export default Logo
