import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import profileReducer from './slices/ProfileSlice.js'
import homeReducer from './slices/HomeSlice.js'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedSettingReducer = persistReducer(persistConfig, profileReducer)

const rootReducer = combineReducers({
  profile: persistedSettingReducer,
  home: homeReducer,
  // accounts: accountsReducer,
  // proxies: proxiesReducer,
})


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      // Ignore all actions that redux-persist has
      // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })),
})


export const persistedStore = persistStore(store)
