const Button = (props) => {
  let colors = ''
  if (props.type === 'primary') {
    colors += 'bg-primary text-black '
  } else {
    colors += 'text-primary bg-black '
  }

  return <button onClick={props.onClick} className={'w-1/2 py-2 font-bold rounded-full ' + colors + (props.className || '')}>
    {props.children}
  </button>
}

export default Button
