import AuthTemplate from "../../components/AuthTemplate.js"
import Input from "../../components/Input.js"
import Button from "../../components/Button.js"
import {useNavigate} from 'react-router-dom'
import {useForm} from "react-hook-form"

const ForgotPassword = () => {
  const navigate = useNavigate()
  const {register, handleSubmit, formState: {errors}} = useForm()

  const sendResetLink = (data) => {

  }

  return <AuthTemplate
    titles={['FORGOT YOUR PASSWORD?', "LET'S MAKE THIS EASY."]}
    descriptions={['If your account exists,', 'you should receive an email within a few moments.', "(If you don't find it, check spam!)"]}
  >
    <form onSubmit={handleSubmit(data => sendResetLink(data))}>
      <Input label={'Email'} type={'email'} placeholder={'email@example.com'} className={'mt-2'} register={register} name={'email'} params={{required: true}}/>
      <div className={'mt-6 flex justify-center'}>
        <input type={'submit'} className={'w-1/2 py-2 font-bold rounded-full text-primary bg-black'} value={'Send Reset Link'}/>
      </div>
    </form>
    <div className={'mt-4 flex justify-center text-gray-500'}>
      - OR -
    </div>
    <div className={'mt-4 flex justify-center'}>
      <Button type={'primary'} onClick={() => navigate('/login')}>SIGN IN</Button>
    </div>
  </AuthTemplate>
}

export default ForgotPassword
