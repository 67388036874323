import Layout from "../../components/Layout.js"

const Subscription = () => {
  return <Layout>
    <div className={'mb-3'}>
      Coming soon
    </div>
  </Layout>
}

export default Subscription
