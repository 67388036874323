import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, setDoc, query, updateDoc, where} from "firebase/firestore"
import {db} from "../firebase.js"

const getByUserId = async (collectionName, userId) => {
  let ret = []
  try {
    const q = query(collection(db, collectionName), where("user_id", "==", userId))
    const snapshot = await getDocs(q)
    snapshot.forEach(doc => {
      ret.push({
        ...doc.data(),
        id: doc.id
      })
    })
  } catch (e) {
    console.log(e.message)
  }
  return ret
}

const getBydId = async (collectionName, id) => {
  try {
    const docRef = doc(db, collectionName, id)
    const snap = await getDoc(docRef)
    return snap.data()
  } catch (e) {
    console.log(e.message)
    return null
  }
}

const updateById = async (collectionName, id, data) => {
  try {
    await updateDoc(doc(db, collectionName, id), data)
    return true
  } catch (e) {
    console.log(e.message)
    return false
  }
}

const setById = async (collectionName, id, data) => {
  try {
    await setDoc(doc(db, collectionName, id), data)
    return true
  } catch (e) {
    console.log(e.message)
    return false
  }
}

const deleteById = async (collectionName, id) => {
  try {
    await deleteDoc(doc(db, collectionName, id))
    return true
  } catch (e) {
    console.log(e.message)
    return false
  }
}

const add = async (collectionName, data) => {
  try {
    await addDoc(collection(db, collectionName), data)
    return true
  } catch (e) {
    console.log(e.message)
    return false
  }
}

export default {
  getByUserId,
  getBydId,
  setById,
  updateById,
  deleteById,
  add
}
