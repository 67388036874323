import DataModel from './DataModel.js'
const COLLECTION = 'Accounts'

export default {
  getAccountsByUserId: userId => DataModel.getByUserId(COLLECTION, userId),
  getBydId: id => DataModel.getBydId(COLLECTION, id),
  updateById: (id, data) => DataModel.updateById(COLLECTION, id, data),
  deleteById: id => DataModel.deleteById(COLLECTION, id),
  add: data => DataModel.add(COLLECTION, data)
}
